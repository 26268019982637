<template id="guardian-layout">
  <div id="guardian-layout">
    <NavBar v-if="!isSetupPage"/>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "../pages/guardian/components/AppNavbarMobile.vue";
export default {
  components: {
    NavBar,
  },
  computed: {
    isSetupPage() {
      const get_route_path = this.$route.path;
      return get_route_path.includes('setup');
    }
  }
};
</script>

<style lang="scss" scoped>
  #guardian-layout {
    height: 100%;
  }
</style>