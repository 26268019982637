<template>
  <div style="background: #36d5f2;">
    <nav
      v-if="!['modulr-verification', 'add-funds'].includes($route.name)"
      class="tabs"
      style="display: block; height: 90px"
    > 
      <ul class="-primary">
        <li v-for="page in pages" :key="page.id" style="position: relative">
          <RouterLink
            replace
            style="display: flex; flex-direction: column"
            :to="page.link"
            class="appNavbar__listLink routerItem"
            :class="{ tabs__active: page.link === $route.path }"
          >
            <!-- <img
              :src="require(`../assets/images/navbar-icons/${page.icon}.svg`)"
              width="23"
              :class="{ active_svg: page.link === $route.path }"
              :alt="page.icon"
            /> -->
            <img
      :src="require(`../assets/images/navbar-icons/${page.icon}.svg`)"
      width="40"
      class="nav_icon"
      :class="{ active_svg: page.link === $route.path }"
      :alt="page.icon"
    />

            <span :class="{ active_svg: page.link === $route.path }" class="nav_icon" style="font-size: 12px; margin-top: 5px">{{ page.name }}</span>
          </RouterLink>
        </li>
      </ul>
    </nav>

    <!---------------- Start Modulr Verification Navbar --------------->

    <div
      v-if="
        !['guardian-profiles', 'modulr-verification', 'add-funds'].includes(
          $route.name
        )
      "
      class="appNavbarMobile__container"
      style="
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        padding: 2px 15px;
        left: 0;
        right: 0;
        width: 100%;
        height: 60px;
        background: #ffffff;
        z-index: 9999;
      "
      @closeMenu="navbarMobileToggle"
    >
      <div
        style="display: flex; width: 35px;"
        :class="{
          'opacity-0 pointer-events-none': ['guardian-profile'].includes(
            $route.name
          ),
        }"
      >
        <button class="navbar_button_requests">
          <img
            v-if="!$store.state.hasNotifications"
            src="../assets/images/notifications_icon.svg"
            width="35"
            @click="loginRequestsSubmit"
            alt=""
          />
          <img
            v-else
            src="../assets/images/bell-bullet.svg"
            width="25"
            @click="loginRequestsSubmit"
            alt=""
          />
        </button>
      </div>

      <img src="../assets/images/logo-color.png" class="nav-logo" alt="" />

      <span
        :class="{
          'opacity-0 pointer-events-none': ['guardian-profile'].includes(
            $route.name
          ),
        }"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <RouterLink
          replace
          to="/guardian/profile"
          class="appNavbar__listLink navbar_button_requests"
          style="font-size: 0px"
        >
          <avatar
            :fullname="user.first_name + ' ' + user.last_name"
            :size="35"
            color="#A1DEF9"
          />
        </RouterLink>
      </span>
      <!-- Object.keys(miniMissionsNewGetter).length == 0 || Object.keys(getSetupChildrens).length == 0 || guardianBalance == 0 -->

      <AppAccordion style="display: none;"
        v-if="accountSetupStatusGetter === 0"
        class="mb-4 absolute top-[60px] w-full left-0"
      >
        <template v-slot:title>
          <span class="font-semibold text-xs">What to do next</span>
        </template>
        <template v-slot:content>
          <ul class="text-xs font-semibold list-disc pl-7 pr-5">
            <li class="py-2 flex justify-between items-center">
              <span>Add a child</span>
              <img
                v-if="Object.keys(getSetupChildrens).length > 0"
                src="../assets/images/list-tick.svg"
                alt="cross"
              />
              <img v-else src="../assets/images/list-cross.svg" alt="cross" />
            </li>
            <li class="py-2 flex justify-between items-center">
              <span>Add a mission</span>
              <img
                v-if="miniMissionsLengthGetter > 0"
                src="../assets/images/list-tick.svg"
                alt="cross"
              />
              <img v-else src="../assets/images/list-cross.svg" alt="cross" />
            </li>
            <li class="py-2 flex justify-between items-center">
              <span>Top up your balance to earn up to £10</span>
              <img
                v-if="guardianBalance > 0"
                src="../assets/images/list-tick.svg"
                alt="cross"
              />
              <img v-else src="../assets/images/list-cross.svg" alt="cross" />
            </li>
          </ul>
        </template>
      </AppAccordion>
    </div>

    <transition name="dropdownMenu">
      <AppNavbar v-if="dropdown" class="dropdown" />
    </transition>

    <Sheet :show.sync="sheetLoginRequests" :on-close="closeSheetLoginRequests">
      <div slot="title">
        <img
          src="../assets/images/logo-color.png"
          alt="Logo"
          class="h-[35px] mt-4"
        />
      </div>

      <!-------------------- PROFILE BUTTONS --------------------->
      <div class="card-buttons pt-6 z-10 bg-white">
        <button
          :class="{ isActive: active_el == 1 }"
          data-section="#notifications"
          @click="activate(1, '#notifications')"
        >
          Notifications
        </button>
        <button
          :class="{ isActive: active_el == 2 }"
          data-section="#loginRequests"
          @click="activate(2, '#login_requests')"
        >
          Login Requests
        </button>
      </div>

      <!-------------------- PROFILE SECTIONS --------------------->
      <div class="card-main">
        <div
          id="notifications"
          class="card-section"
          :class="{ isActive: active_el == 1 }"
        >
          <div
            class="activity_item border border-[#e3e3e3] flex-col mt-3"
            style="height: auto"
          >
            <div class="flex items-center justify-between">
              <div class="activity_item__container">
                <div class="activity_item__icon">
                  <img
                    src="../assets/images/bell-bullet.svg"
                    alt=""
                    style="
                      height: 40px;
                      width: 40px;
                      min-height: 40px;
                      min-width: 40px;
                      border-radius: 0;
                      padding: 5px;
                    "
                  />
                </div>
                <div>
                  <div
                    class="activity_item__title capitalize"
                    style="word-wrap: break-word"
                  >
                    Coming Soon!
                  </div>
                  <div class="activity_item__description">
                    <div>Notifications currently in progress.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="loginRequests"
          class="card-section"
          :class="{ isActive: active_el == 2 }"
        >
          <template v-if="Object.keys(loginRequests).length > 0">
            <div class="my-6 font-bold" style="color: var(--main)">
              Attempted Logins
            </div>
            <div v-if="Object.keys(loginRequests.pending_logins).length > 0">
              <div id="logins" class="scroll-container">
                <div
                  v-for="(device, index) in loginRequests.pending_logins"
                  :key="index"
                  class="scroll-container__item"
                >
                  <div
                    class="activity_item shadow border border-[#DEDEDE] flex-col"
                    style="padding: 15px"
                  >
                    <div>
                      <div class="activity_item__container">
                        <div class="activity_item__icon">
                          <img
                            :src="resolveImage(device.family.user.avatarLink)"
                            alt=""
                          />
                        </div>
                        <div>
                          <div
                            class="activity_item__title capitalize"
                            style="word-wrap: break-word"
                          >
                            {{ device.family.user.first_name }}
                            {{ device.family.user.last_name }}
                          </div>
                          <div class="activity_item__description">
                            <div>{{ timeAgo(device._created_at) }}</div>
                            <div>
                              {{ userAgent(device.user_agent_name) }}
                              <!-- {{device.user_agent_name}} -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="activity_item__amount flex items-center">
                      <img
                        src="../assets/images/icon-lock.svg"
                        class="w-[16px] h-[16px] mt-[-2px]"
                        alt="Pending"
                      />
                    </div>
                    <div
                      class="mx-auto mt-4 mb-8 flex justify-between items-center w-full"
                    >
                      <button
                        class="bg-[#56B897] p-2 rounded-xl font-semibold shadow-lg text-white text-xs w-full mr-2"
                        style="font-family: var(--sans) !important"
                        @click="
                          activateDeviceSubmit(device.token, device.users_id)
                        "
                      >
                        Approve
                      </button>
                      <button
                        class="bg-[#FF8F8F] p-2 rounded-xl font-semibold shadow-lg text-white text-xs w-full ml-2"
                        style="font-family: var(--sans) !important"
                        @click="
                          deactivateDeviceSubmit(device.token, device.users_id)
                        "
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="text-center mx-auto text-[#C4C4C4] font-semibold"
            >
              No Attempted logins!
            </div>

            <div class="my-6 font-bold" style="color: var(--main)">
              Active Logins
            </div>
            <div id="loginsActive">
              <div v-if="Object.keys(loginRequests.active_logins).length > 0">
                <div
                  v-for="(device, index) in loginRequests.active_logins"
                  :key="index"
                >
                  <div
                    class="activity_item shadow border border-[#DEDEDE] flex-col"
                    style="height: auto"
                  >
                    <div class="flex items-center justify-between">
                      <div class="activity_item__container">
                        <div class="activity_item__icon">
                          <img
                            :src="resolveImage(device.family.user.avatarLink)"
                            style="
                              height: 45px;
                              width: 45px;
                              min-height: 45px;
                              min-width: 45px;
                            "
                            alt=""
                          />
                        </div>
                        <div>
                          <div
                            class="activity_item__title capitalize"
                            style="word-wrap: break-word"
                          >
                            {{ device.family.user.first_name }}
                            {{ device.family.user.last_name }}
                          </div>
                          <div
                            class="activity_item__description"
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              max-width: 150px;
                            "
                          >
                            <div>{{ timeAgo(device._created_at) }}</div>
                            <div>
                              {{ userAgent(device.user_agent_name) }}
                              <!-- {{device.user_agent_name}} -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        class="text-white text-xs py-1 px-2 rounded-[100px] bg-[#01A4C0] mr-1"
                        @click="
                          deactivateDeviceSubmit(device.token, device.users_id)
                        "
                      >
                        Deactivate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="text-center mx-auto text-[#C4C4C4] font-semibold"
              >
                No Attempted logins!
              </div>
            </div>
          </template>
          <div v-else class="text-center text-[#C4C4C4] font-semibold">
            You have no children added
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetConfirmLogin" :on-close="closeSheetConfirmLogin">
      <div
        v-if="loginRequest.type == 'deactivate'"
        style="
          font-weight: 600;
          text-align: center;
          font-size: 14px;
          margin: 10px 0 30px 0;
        "
      >
        Do you really want to deactivate this session?
      </div>
      <div
        v-else-if="loginRequest.type == 'activate'"
        style="
          font-weight: 600;
          text-align: center;
          font-size: 14px;
          margin: 10px 0 30px 0;
        "
      >
        Do you want to accept this session?
      </div>
      <button
        v-if="loginRequest.type == 'deactivate'"
        class="saveAddressButton"
        @click="
          deactivateDeviceSubmit(loginRequest.userToken, loginRequest.userId)
        "
      >
        Deactivate
      </button>
      <div
        v-else-if="loginRequest.type == 'activate'"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <button
          class="saveAddressButton"
          style="margin-right: 5px"
          @click="
            deactivateDeviceSubmit(loginRequest.userToken, loginRequest.userId)
          "
        >
          Reject
        </button>
        <button
          class="saveAddressButton"
          style="margin-left: 5px"
          @click="
            activateDeviceSubmit(loginRequest.userToken, loginRequest.userId)
          "
        >
          Accept
        </button>
      </div>
    </Sheet>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  import Sheet from "../components/Sheet";
  import AppAccordion from "../components/AppAccordion";
  // import AppSheet from "@/components/AppSheet";
  import axios from "axios";
  import config from "../config";
  import status from "../utils/statusCode";
  import Avatar from "vue-avatar-component";
  import moment from "moment";

  export default {
    components: {
      // AppSheet,
      Sheet,
      Avatar,
      AppAccordion,
    },
    computed: {
      ...mapGetters([
        "guardianAvatar",
        "loginRequests",
        "guardian",
        "miniMissionsNewGetter",
        "getSetupChildrens",
        "guardianBalance",
        "accountSetupStatusGetter",
        "miniMissionsLengthGetter",
      ]),
    },
    data() {
      return {
        user: [],
        sheetConfirmLogin: false,
        dropdown: false,
        drawer: false,
        sheet: false,
        active_el: 2,
        cardState: "#login_requests",
        sheetLoginRequests: false,
        loginRequest: {
          userToken: null,
          userId: null,
          type: null,
        },
        faq: {
          id: 6,
          name: "Help & Report",
          link: "/guardian/help",
        },
        pages: [
          { id: 1, icon: "home", name: "Home", link: "/guardian" },
          {
            id: 2,
            icon: "missions",
            name: "Missions",
            link: "/guardian/missions",
          },
          {
            id: 3,
            icon: "childrens",
            name: "Children",
            link: "/guardian/children",
          },
          // { id: 4, icon: "funds", name: "Funds", link: "/funds" },
          {
            id: 5,
            icon: "financial",
            name: "Financial",
            link: "/guardian/financial",
          },
          //{ id: 6, name: 'Help & Report', link: '/help' }
        ],
      };
    },
    created() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);

      if (params.get("action") === "OPEN_NOTIFICATIONS") {
        this.loginRequestsSubmit();
      }

      const fetchUser = async () => {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        const family = localStorage.getItem("guardian_family_id");

        await axios
          .post("/user/get", {
            token: token,
            userId: userId,
            family_id_check: family,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              // var account = response.data.data;
              // console.log(response.data.data);
              this.user = response.data.data;
            } else if (status.invalidToken(statusCode)) {
              deleteStorage();
            } else if (status.error(statusCode)) {
              Vue.prototype.$toast(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            throw error;
          });
      };

      fetchUser();
    },
    methods: {
      ...mapActions([
        "fetchLoginRequests",
        "activateDevice",
        "deactivateDevice",
        "TOAST",
      ]),
      timeAgo(value) {
        var time = moment(value).utc();
        // console.log('time: ', time);
        //     console.log(moment(value)
        // .subtract(moment(value).utcOffset(), 'minutes')
        // .format("LLL"))

        return moment(time, "LLL").fromNow();
      },
      userAgent(agent) {
        if (agent.includes("Macintosh", "iPhone", "iOS")) {
          return "iPhone";
        } else if (agent.includes("AndroidOS", "Android")) {
          return "Android";
        } else {
          return "Unknown device";
        }
      },
      activate: function (el, state) {
        this.active_el = el;
        this.cardState = state;
      },
      async loginRequestsSubmit() {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        const family = localStorage.getItem("guardian_family_id");
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const response = await axios.get(
          "/userAgents/test?userId=" +
            userId +
            "&token=" +
            token +
            "&family_id_check=" +
            family +
            "&timezone=" +
            timezone
        );
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          this.$store.commit("LOGIN_REQUESTS", response.data);
          this.openSheetLoginRequests();
        } else if (status.invalidToken(statusCode)) {
          this.deleteStorage();
        } else if (status.error(statusCode)) {
          this.TOAST("Something went wrong! Please contact our support team.");
        }
      },
      openSheetLoginRequests() {
        this.sheetLoginRequests = true;
      },
      closeSheetLoginRequests() {
        this.sheetLoginRequests = false;
      },
      sheetConfirmLoginMethod(token, id, type) {
        this.loginRequest.userToken = token;
        this.loginRequest.userId = id;
        this.loginRequest.type = type;
        this.openSheetConfirmLogin();
      },
      openSheetConfirmLogin() {
        this.closeSheetLoginRequests();
        this.sheetConfirmLogin = true;
      },
      closeSheetConfirmLogin() {
        this.sheetConfirmLogin = false;
      },
      activateDeviceSubmit(userToken, userId) {
        const form = {
          usrToken: userToken,
          usrId: userId,
        };

        this.activateDevice(form);
        this.closeSheetConfirmLogin();
      },
      deactivateDeviceSubmit(userToken, userId) {
        const form = {
          usrToken: userToken,
          usrId: userId,
        };
        console.log(form);
        this.deactivateDevice(form);
        this.closeSheetConfirmLogin();
      },
      navbarMobileToggle() {
        this.dropdown = !this.dropdown;
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      navicon(img) {
        return require(`../assets/images/navbar-icons/${img}.svg`);
      },
    },
  };
</script>

<style lang="scss">
.active_svg {
  fill: #ff0000; /* Change this color to your desired active color */
}

  .point {
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 5px;
  }

  .navbar_button_requests {
    border: none;
    background: transparent;

    &:active {
      opacity: 0.5;
    }
  }

  .appNavbarMobile {
    width: 100%;

    &__container {
      padding: 15px;
      display: flex;
      justify-content: space-between;

      &__menu {
        width: 50px;
        height: 50px;
        background: #ffffff;
        border-radius: 100%;
        background-image: url("../assets/images/navbarMenu.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
        cursor: pointer;
      }
    }
  }

  .appNavbarMobile__container
    .appNavbar__listLink.navbar_button_requests
    .avatar {
    color: #806157;
  }

  .tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    // background: rgba(255, 255, 255, 0.8);
    // backdrop-filter: blur(7px);
    z-index: 9999;

    &__active {
      background: white;
    }
  }

  .tabs:not(.--jsfied) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .tabs .--hidden {
    display: none;
  }

  .tabs a,
  .tabs button {
    width: 100%;
    height: 100%;
    display: block;
    font-size: 1em;
    line-height: 1.2;
    text-align: center;
    color: #01a4c0;
  }

  .activeTab {
    background-color: #ce4ba9;
  }

  .tabs .-primary {
    display: -webkit-box;
    display: flex;
  }

  .tabs ul {
    list-style-type: none;
  }

  .tabs .-primary > li {
    -webkit-box-flex: 1;
    flex-grow: 1;
    //background-color: #fff;
  }

  // .tabs .-primary > li + li {
  //   border-left: 1px solid #36d5f270;
  // }

  .tabs ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs .-primary > li > a,
  .tabs .-primary > li > button {
    white-space: nowrap;
    padding: 0.7em 0.6em;
    //box-shadow: inset 0 -0.2em 0 #b61a3e;
  }

  .tabs .-primary .-more {
    background-color: #fff;
  }

  .tabs .-primary .-more > button span {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .tabs.--show-secondary .-primary .-more > button span {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .tabs .-secondary {
    max-width: 100%;
    min-width: 10em;
    display: none;
    position: absolute;
    top: -197px;
    z-index: 9999;
    right: 0;
    box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
    -webkit-animation: nav-secondary 0.2s;
    animation: nav-secondary 0.2s;
  }

  .nav-logo {
    height: 35px;
  }

  .routerItem {
    &:active {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  #loginsRequests {
    h1 {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .recent_container {
      margin-top: 20px;
    }

    .activity_item {
      width: 100%;
      height: 100px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      // background: var(--gray);
      background: white;
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(18, 205, 254, 0.2) 0px 0px 0px 1px;
      // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, #7c8db515 0px 0px 0px 1px;
      padding: 15px;
      border-radius: 5px;
      border-bottom-right-radius: 15px;
      position: relative;
      overflow: hidden;
      transition: all 200ms ease-in-out !important;

      &:active {
        opacity: 0.6;
      }

      &__container {
        display: flex;
        align-items: center;
      }

      &__title {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.01rem;
        // text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 200px;
      }

      &__description {
        font-size: 11px;
        color: #9b9b9b;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 280px;
        // letter-spacing: 0.02rem;
        // text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
      }

      &__amount {
        position: absolute;
        top: 10px;
        right: 15px;
        color: var(--main);
        font-weight: 600;
        font-size: 11px;
      }

      &__icon {
        // width: 50px;
        // height: 50px;
        // border: none;
        // border-radius: 13px;
        // display: flex;
        margin-right: 15px;
        display: flex;
        align-items: center;
        // align-items: center;
        // justify-content: center;
        // background: var(--gray);

        img {
          width: 50px;
        }
      }
    }
  }

  .saveAddressButton {
    background: var(--red);
    border: none;
    width: 100%;
    color: white;
    padding: 15px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
    // position: fixed;
    // left: 0;
    // right: 0;
    // bottom: 7px;
    margin: 0 auto;
    cursor: pointer;
  }

  #logins,
  #loginsActive,
  #notifications {
    h1 {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .recent_container {
      margin-top: 20px;
    }

    .activity_item {
      width: 100%;
      height: 150px;
      margin-bottom: 8px;
      display: flex;
      /*align-items: center;*/
      // background: var(--gray);
      background: white;
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(18, 205, 254, 0.2) 0px 0px 0px 1px;
      // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, #7c8db515 0px 0px 0px 1px;
      padding: 5px;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      transition: all 200ms ease-in-out !important;

      &:active {
        opacity: 0.6;
      }

      &__container {
        display: flex;
        align-items: center;
      }

      &__title {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.01rem;
        // text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // width: 110px;
      }

      &__description {
        font-size: 11px;
        color: #9b9b9b;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // width: 185px;
        // letter-spacing: 0.02rem;
        // text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
      }

      &__amount {
        position: absolute;
        top: 10px;
        right: 15px;
        color: var(--main);
        font-weight: 600;
        font-size: 11px;
      }

      &__icon {
        // width: 50px;
        // height: 50px;
        // border: none;
        // border-radius: 13px;
        // display: flex;
        margin-right: 15px;
        display: flex;
        align-items: center;
        // align-items: center;
        // justify-content: center;
        // background: var(--gray);

        img {
          width: 65px;
          height: 65px;
          border-radius: 50%;
        }
      }
    }
  }

  .card {
    margin: auto;
    // overflow-y: auto;
    // position: relative;
    z-index: 1;
    // overflow-x: hidden;
    // background-color: rgba(255, 255, 255, 1);
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    border-radius: 10px;
    height: 100%;
    // box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
  }

  .card[data-state="#general"] {
    .card-main {
      padding-top: 0;
    }
  }

  .card.isActive {
    .card-header {
      height: 80px;
    }

    .card-buttons {
      // background: white;
      z-index: 10;
    }

    .card-cover {
      height: 100px;
      top: -50px;
    }

    .card-avatar {
      transform: none;
      left: 20px;
      width: 50px;
      height: 50px;
      bottom: 10px;
    }

    .card-fullname,
    .card-jobtitle {
      left: 86px;
      transform: none;
    }

    .card-fullname {
      bottom: 28px;
      font-size: 19px;
    }

    .card-jobtitle {
      bottom: 10px;
      letter-spacing: 1px;
      font-size: 10px;
    }
  }

  .card-header {
    position: relative;
    display: flex;
    height: 200px;
    flex-shrink: 0;
    width: 100%;
    transition: 0.15s;
    margin-bottom: 35px;

    // * {
    //   transition: 0.3s;
    // }
  }
  .card-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    height: 160px;
    top: -20%;
    left: 0;
    will-change: top;
    background-size: cover;
    background-position: center;
    filter: blur(30px);
    transform: scale(1.2);
    transition: 0.5s;
  }

  .card-avatar {
    width: 80px;
    height: 80px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-64px);
    background: rgba(255, 255, 255, 0.5);
    padding: 5px;
  }

  .card-fullname {
    position: absolute;
    bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    transform: translateY(-10px) translateX(-50%);
    left: 50%;
    color: var(--secondary);
  }

  .card-jobtitle {
    position: absolute;
    bottom: -2px;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    // text-transform: uppercase;
    // letter-spacing: 1.5px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-7px);
  }

  .card-main {
    position: relative;
    flex: 1;
    display: flex;
    // padding-top: 10px;
    flex-direction: column;
  }

  .card-subtitle {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .card-content {
    padding: 20px;
    margin-bottom: 65px;
  }

  .card-desc {
    line-height: 1.6;
    color: var(--secondary);
    font-size: 14px;
    margin: 0;
    font-weight: 400;
  }

  .card-social {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 30px;
    svg {
      fill: rgb(165, 181, 206);
      width: 16px;
      display: block;
      transition: 0.3s;
    }
    a {
      color: #8797a1;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      background-color: rgba(93, 133, 193, 0.05);
      border-radius: 50%;
      margin-right: 10px;

      &:hover {
        svg {
          fill: darken(rgb(165, 181, 206), 20%);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .card-buttons {
    display: flex;
    margin-top: auto;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;

    button {
      flex: 1 1 auto;
      user-select: none;
      background: 0;
      font-size: 13px;
      border: 0;
      padding: 15px 5px;
      cursor: pointer;
      color: #c4c4c4;
      font-family: var(--sans) !important;
      transition: 0.3s;
      font-weight: bold;
      outline: 0;
      border-bottom: 3px solid #c4c4c4;

      &.isActive {
        color: #01a4c0;
        border-bottom: 3px solid #01a4c0;
        // background: linear-gradient(
        //   to bottom,
        //   rgba(127, 199, 231, 0) 0%,
        //   rgba(207, 204, 255, 0.2) 44%,
        //   rgba(211, 226, 255, 0.4) 100%
        // );
      }
    }
  }

  .card-section {
    display: none;
    &.isActive {
      display: block;
      animation: fadeIn 0.6s both;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translatey(40px);
    }
    100% {
      opacity: 1;
    }
  }

  .card-timeline {
    margin-top: 30px;
    position: relative;
    &:after {
      background: linear-gradient(
        to top,
        rgba(134, 214, 243, 0) 0%,
        rgba(81, 106, 204, 1) 100%
      );
      content: "";
      left: 42px;
      width: 2px;
      top: 0;
      height: 100%;
      position: absolute;
      content: "";
    }
  }

  .card-item {
    position: relative;
    padding-left: 60px;
    padding-right: 20px;
    padding-bottom: 30px;
    z-index: 1;
    &:last-child {
      padding-bottom: 5px;
    }

    &:after {
      content: attr(data-year);
      width: 10px;
      position: absolute;
      top: 0;
      left: 37px;
      width: 8px;
      height: 8px;
      line-height: 0.6;
      border: 2px solid #fff;
      font-size: 11px;
      text-indent: -35px;
      border-radius: 50%;
      color: rgba(#868686, 0.7);
      background: linear-gradient(
        to bottom,
        lighten(#516acc, 20%) 0%,
        #516acc 100%
      );
    }
  }

  .card-item-title {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .card-item-desc {
    font-size: 13px;
    color: #6f6f7b;
    line-height: 1.5;
    font-family: "DM Sans", sans-serif;
  }

  .card-settings-wrapper {
    margin-top: 20px;
  }

  .card-settings {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #6f6f7b;
    font-family: "DM Sans", sans-serif;
    line-height: 1.6;
    cursor: pointer;

    & + & {
      margin-top: 16px;
    }

    svg {
      flex-shrink: 0;
      width: 30px;
      min-height: 34px;
      margin-right: 12px;
      transition: 0.3s;
      padding-right: 12px;
      border-right: 1px solid #dfe2ec;
    }
  }

  .scroll-container {
    display: flex;
    min-width: 100%;
    overflow-x: auto;
  }
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  .scroll-container__item {
    min-width: 95%;
    margin-right: 10px;
  }

  .active_svg{
    opacity: 1 !important;
  }
  .nav_icon{
    opacity: 0.4;
  }
</style>
